export const groupBy = <T, TKey>(array: Array<T>, getKey: (item: T) => TKey): Map<TKey, T[]> => {
    const result = array.reduce((map, current) => {
        const key = getKey(current)

        const group = map.get(key) || []

        group.push(current)

        map.set(key, group)

        return map
    }, new Map<TKey, T[]>())

    return result
}

// export const orderBy = <T>(array: Iterable[], compare: (a: T, b: T) => number): T[] => {
    
//     return new Array<T>()
// }